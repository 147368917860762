.throbber {
    display: block;
    font-size: 100px;
    border: .07em solid rgba(255, 255, 255, 0.35);
    border-top-color: rgba(156, 156, 156, 0.35);
    border-bottom-color: rgba(156, 156, 156, 0.35);
    width: .4em;
    height: .4em;
    border-radius: 1em;
    animation: rotate 4s infinite linear;
}


.throbbing{
    display: flex;
    align-items:center;
    justify-content: center;
}

@keyframes rotate {
    from {
        transform: rotate(-360deg)
    }
}
