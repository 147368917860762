.menu-top{
	line-height:initial;
	background: white;
	min-height: var(--menuHeight);
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 17px;
	user-select: none;
	
	top: 0px;
	justify-content: center;
	z-index:500;
	box-shadow:0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
	position: sticky;
	position: -webkit-sticky;
}
.headroom{
	z-index:500 !important;
	transition: all 0.3s ease-in-out !important;
}
.headroom .menu-top{
	position:relative;
}

.menu-top a{
	color: var(--greyText);	
}

.menu-logo{
	margin-top:9px;
	position:relative;
	z-index:2;
}

.H .menu-top a:hover{
	color: black;	
}
.menu-item{

	height: 2.35em;
	padding: 0 .55em;
	display:flex;
	align-items:center;
}
.menu-top .menu-item.active{
	color:white !important;
	background:var(--red);
}
.menu-top .menu-lang-switch{
	font-size: 15px;
	padding: 3px;
	color:#999;
}
.menu-top .menu-lang-switch.active{
	color:black;
}


.menu-top-nav{
	display: flex;
	flex-wrap: wrap;
	position:relative;
}
.menu-top-inner{
	display: grid;
	align-items:center;
	grid-template-columns: min-content 1fr;
	grid-row-gap: 5px;
}

@media screen and (min-width:851px) {
	.menu-top-inner{
		padding-right: 75px
	}
	.menu-right{
		position: absolute;
		top: 0;
		min-height:70px;
		height: 100%;

		left:0;
		width: 100vw;
		padding-right:20px;
		

	}
	.H .menu-right{
		width: 100%;
	}
	.menu-top-nav{
		margin-left: 30px;
	}
}



.menu-amo{
	height:auto;
	width: 135px;
	display:block;

	margin-right:30px;

}
.menu-right{
	display: flex;
	

	flex-direction:row;
	align-items: center;
	justify-content: flex-end;
	
}

@media screen and (min-width:851px) and (max-width: 1199px){
	.menu-amo{
		height:17px;
		width:auto;
		margin: 0 0 4px;
	}
	.menu-right{
		flex-direction: column;
		align-items: flex-end;
		justify-content: center
	}
}
@media screen and (max-width: 850px){

		.menu-top-nav{
		grid-column-end:span 2;
		justify-content: center;
	}
	.menu-right{
		justify-self: flex-end;
	}
	@media (min-width:601px) {
		:root{
		--menuHeight: 113px;

		}	
	}



	.menu-top .menu-lang-switch{
		padding: 8px;
		font-size:inherit;
	}
}












.hamburger {
	background: var(--red);
	display: block;
	position: relative;
	width: 50px;
	height: 50px;
	cursor: pointer;
	margin-left: 25px;
}

.hamb {
	position: absolute;
	z-index: 3;
	top: 10px;
	left: 10px;
	width: 30px;
	height: 30px;
	transition: transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}








.hamb-crust-top {
	top: 14px;
	transform: translateY(-4px);



}

.hamb-crust-bottom {
	bottom: 14px;
	transform: translateY(4px);
}

.hamb-crust {
	display: block;
	transition: transform .1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) .1008s;
	width: 24px;
	height: 1px;
	background: #fff;
	border-radius: 0.5px;
	position: absolute;
	left: 3px;
	z-index: 1;
}









.hamburger-checked .hamb-crust{
	transition: transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
	transform: none;
}




.hamburger-checked .hamb{
	transition: transform .3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) .1008s;
}

.hamburger-checked .hamb-bottom{
	transform: rotate(-45deg);
}

.hamburger-checked .hamb-top {
	transform: rotate(45deg);
}


.mobile-menu{
	position:absolute;
	top:100%;
	left:0;
	width:100%;
	height:0;
	background:var(--red);
	
	z-index: 5;
	    overflow: hidden;
	transition: height .56s cubic-bezier(0.52, 0.16, 0.24, 1);
	
}

.mobile-menu.shown,.mobile-menu-inner{
	height:calc(100vh - var(--menuHeight));
}
.mobile-menu-inner{
	
display: flex;
	flex-direction: column;
	padding: 0 var(--gap);
	font-weight: 400;
	overflow:auto;	
}
.mobile-menu a{
	color:white !important;

	padding: 15px 0;
}
.mob-item{
	border-bottom:1px solid var(--darkRed);
	position:relative;
}
.mob-item.active::after{
	content: "";
	position: absolute;
	top: -1px;
	width: 100%;
	padding: 1px var(--gap);
	background: var(--darkRed);
	height: 100%;
	left: calc(-1 * var(--gap));
	z-index: -1;
}