.container{
display: flex;
    background: white;
    align-items: center;
    padding: 50px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
        min-height: calc(2 * var(--blockWidth));
}

.contain-site{
	min-height:unset;
}


.container h1{
	font-size:72px;
	color:var(--red);
}

.container .title{
	font-size:32px;
	font-weight:300;
}
