.office-map{
	height: 100vmin;

	display:flex;
}
.office-map-inner{
	position:relative;
	flex:1;
}
.map-container{
	background:var(--greybg);
	

	
}
@media screen and (min-width:601px) {
	.office-map{
		position: absolute;
		top:0;
		left:0;
		height: 100%;
		width: 100%;
	}
	.map-container{
		position: relative;
		height: 500px;
		flex: none;
		background:#F0F0F0;
	}


	.contact-info{
		position: relative;
		max-width: max-content;
		font-weight: 400;
		z-index:5;
		color: black;
		--bgc:rgb(240, 240, 240);
		text-shadow:
		var(--bgc) 0 0 4px,
		var(--bgc) 1px 1px 3px,
		var(--bgc) -1px 1px 3px,
		var(--bgc) 1px -1px 3px,
		var(--bgc) -1px -1px 3px;



	}

}

