.tweet{
	display:grid;
	position:relative;
}


.tweet time{
	text-align:right;
	

}
.tweet-overlay-link{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	font-size:0;
	color:transparent;
	opacity:0;
}
.tweet-body a{
color: var(--red);
position:relative;
z-index:1;
}