.case-block{
	position:relative;
	transition: filter .15s;
}

.case-logo{
	display:flex;
	align-items:center;
	justify-content: center;

	position:relative;
	z-index: 15;
	flex:1;
}
.case-img{
	max-height: 80%;
	max-width: 80%;
}
.case-inner-grid{
	--blocks: 1;
	flex: 1;
	    min-height: 100%;
	    width:100%;
	    	background: white;
	position: absolute;
	right:calc(var(--reverse) * 0px);
}
.case-inner-grid .case-summary{
	order:var(--reverse);

}
.case-summary .block-title{
	padding-right: 17px;
}
.is-open{
	z-index:20;
}
.is-open .case-inner-grid{
	--blocks:2;
	width: calc(200% + var(--gap));
	z-index: 5;
}
.is-open .case-logo{
	pointer-events:none;
}
.case-label{
	z-index:3;
	background: black;
	font-size: 13px;
	position: absolute;
	top: 0;
	left: 0;
	color: white;
	padding: .2em .8em;
}


.case-links-title{
margin-bottom: 10px;
}
.case-links{
	flex-direction: column;
}
.case-link{
	padding: 0 15px;
	    margin: 0 -15px;
}
.H a.case-link:hover{
	background:black;
	color:white;
}
.case-link.active{
	background:var(--red) !important;
	color:white;
}
.case-close{
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    width: 40px;
    height: 40px;
    cursor:pointer;
}
.is-not-open{
    filter: brightness(0.7) opacity(0.9);
}

@media screen and (max-width:600px) {
	.case-links{
		grid-column-end:span 2;
	}
	}