

.background-cont{
	background-color:rgb(185,185,185);

    left: 0px;
    bottom:0;
    width: 100%;

    z-index: -1;
    pointer-events: none;
    user-select: none;



    height:0;
    position:sticky;
    position:-webkit-sticky;
}

.background-cont img{
	object-fit: cover;
	mix-blend-mode: color-burn;
	position:absolute;
top:0;
	left:0;
	width:100%;
	    height:100%;
}
.background-cont .img-load{
	background-color:rgb(176, 176, 176);
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	  height: calc(100vh - 70px);

}
@media screen and (max-width:600px) {
	.background-cont .img-load{
		 height: 100vh;
	}
}

.img-load{
	opacity:0;
	transition:opacity .5s .15s;
}
.img-ready{
	opacity:1;
}
