:root{
	--gap:20px;
	--blockWidth:250px;
	--blocks:4;
	--contentWidth:calc(var(--blocks) * (var(--blockWidth) + var(--gap)) - var(--gap));
	--menuHeight:70px;
}


.contain-site{
	min-height: 100vh;
	display: flex;
	flex-direction: column;

}

.main{
	padding-top:var(--gap);
	padding-bottom:var(--gap);

	display: flex;
	align-items: center;
	flex-direction: column;
	flex:1;




}

.article-loading{
	--estimateHeight:2;
	min-height:calc(var(--estimateHeight)*var(--blockWidth));
}



@media screen and (min-width:601px){
	.content-wrap{
		align-items: center;
	}
}

@media screen and (min-width:851px) {
	.case-block:nth-child(4N){
		--reverse:-1;
	}

}

@media screen and (max-width:1100px){
	:root{
		--blockWidth:calc((100vw - (var(--blocks) + 1) * var(--gap)) / var(--blocks));
	}
}
@media screen and (min-width:601px) and (max-width:850px){
	:root{
		--blocks:3;
	}
	.case-block:nth-child(3N){
		--reverse:-1;
	}
	.block-article{
		grid-row-end: span 3;
	}
	.article-img{
		order:5;
	}
	.article-browser{
		order:-5;
	}
}














.content-wrap{
	padding-left:var(--gap);
	padding-right:var(--gap);
	display: flex;
	flex-direction: column;
}
.content-width{
	width: 100%;
	max-width:var(--contentWidth);
}

.content-grid-rows{
	display:grid;
	gap:var(--gap);
}

.content-grid{
	display:grid;
	grid-template-columns: repeat(var(--blocks), 1fr);
	gap:var(--gap);
	grid-auto-flow: dense;
}
.content-grid>*{
	min-width: 0;
	word-wrap: break-word;
}
.content-grid-square{
	grid-auto-rows: minmax(var(--blockWidth), max-content);

}





.span-2{
	grid-column-end:span 2;
}

.article-sections section{
	position:relative;
}
.article-sections{
	display:grid !important;
}
.section-active .title{
	text-decoration:underline;
}


@media screen and (max-width:850px) {
	.handheld-center-content{
		max-width: calc(var(--blockWidth) * 2 + var(--gap));
		grid-template-columns: 1fr;

	}
	.content-pad-cell{
		display:none;
	}
}


@media screen and (max-width:600px){
	:root{
		
		--blocks:2;
		--lightGrey:#a1a1a1;
	}
	body{
		background: var(--lightGrey);
	}
	.twitter-block{
		    --lightGrey: #d8d8d8;
	}
	.case-block:nth-child(2N-1){
		--reverse:-1;
	}

	/*.block-content{
		padding:10px;
		}*/

		.contain-site > .main.content-wrap{
			--gap:1px;
			padding:0;
		}
		.content-width{
			max-width:none;
		}
		.read-more{
			display:none;
		}
		.block-ctr-title{
			font-size: 7.5vw;
		}



		.block-title{
			font-size: 18px;
		}
	}