
.block{
	background:white;
	display:flex;
}
.block-content{
	padding:15px;
	display:flex;
	flex-direction:column;
	    min-width: 0;
}


.block-lead{
	flex:1;
	padding-top:1em;
}

.block-title{
	font-size: 20px;
	color:black;
	line-height:initial;
}

.block-label{
	color:var(--red);
	font-weight:400;
	text-transform: uppercase;
	font-size: 12px;
	margin-top:5px;
}
.thumb{

	object-fit: cover;
	background: #e9e9e9;

}
.read-more{
	color:black;
	font-size: 13px;
	margin-top: .5em;
	display: inline-block;
}

.block-sq2{
	grid-column-end: span 2;
	display:grid;
	grid-template-columns:1fr 1fr;
	gap:var(--gap);
}
.block-sq2 .thumb{
	display:block;
	height:100%;
	width:calc(100% + 0.5 * var(--gap));
}
.block-ctr-title{
	display:flex;
	justify-content: center;
	align-items:center;
	font-size:35px;
	text-align:center;
	padding:10px;
	line-height:1.2;
	text-transform:lowercase;
	color:var(--red);
}

.red-bg{
	background:var(--red);
	color:white;
}

.block-article{
	grid-column-end:span 2;
	grid-row-end:span 2;
}
.block-header{
	border-bottom:1px solid var(--lightGrey);
	flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.border-block+.border-block{
	border-top: 1px solid var(--lightGrey);

}

