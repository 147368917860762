
@font-face {
	font-family: 'Roboto Mono';
	font-display:block;
	font-style: normal;
	font-weight: 300;
	src: local(''),
	url('../fonts/roboto-mono-v11-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/roboto-mono-v11-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}



.behindthenumbers-cont{
	--h:2.4em;
	height:0;
	position:relative;
	z-index:10;
}


.behindthenumbers{
	
	background-color: #DC0814;
	width:100%;
	text-align:center;
	color:white;
	font-weight:300;
	font-family: 'Roboto Mono', "Courier New", Courier, monospace;
	word-break: break-word;
	user-select:none;

	
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: .18em;
	transform: translatey(calc(-1.01 * var(--h)));
	opacity:0;
	height:var(--h);
	transition:transform 350ms, opacity 0ms 360ms;
	position:absolute;
	
}
.behindthenumbers-cont.open{
	height:var(--h);

}
.behindthenumbers-cont.open .behindthenumbers{
	transform:none;
	opacity:1;
		transition:opacity 0ms, transform 360ms 15ms;
}

@media screen and (min-width:601px) {
	.behindthenumbers-cont{
		font-size:4vw !important;
	}
}

@media screen and (min-width:851px) {
	.behindthenumbers-cont{
		font-size:40px !important;
	}
}