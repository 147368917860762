*{
    margin:0;
    padding:0;
    outline:none;
    border:none;
    border-radius:0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-appearance:none;
    box-sizing:border-box;
    font:inherit;
    color: inherit;
    background:none;
}


textarea{resize: none; overflow-x: hidden;}
menu{
    list-style: none;
}
table{
    border-collapse:collapse;
}
:focus {outline:none;}
::-moz-focus-inner {
    border: 0;
}