
@font-face{
	font-family:'helvetica web';
	font-display: fallback;
	font-weight:100;
	src:url(../../fonts/helvetica_light.eot);
	src:url(../../fonts/helvetica_light.eot) format('embedded-opentype'),url(../../fonts/helvetica_light.woff) format('woff'),url(../../fonts/helvetica_light.ttf) format('truetype'),url(../../fonts/helvetica_light.svg#HelveticaLTLightRegular) format('svg');
}


@font-face{
	font-family:'helvetica web';
	font-display: fallback;
	font-weight:400;
	src:url(../../fonts/Helvetica.eot);
	src:url(../../fonts/Helvetica.eot) format('embedded-opentype'),url(../../fonts/Helvetica.woff) format('woff'),url(../../fonts/Helvetica.ttf) format('truetype'),url(../../fonts/Helvetica.svg#HelveticaLTLightRegular) format('svg');
}


:root{
	--red:#D6111A;
	--darkRed:#a60202;
	--greyText: #444;
	--lightGrey:#E3E3E3;
	--greybg:#dedede;
	line-height:1.45;
	overflow-y:scroll;
}
body{
	font-size:16px;
	color:var(--greyText);
	font-family: 'helvetica web', Helvetica, Arial, sans-serif;
	font-weight:100;
	background:black;
}
h1,h2,h3, .reg-font{
	font-weight:400;
}
.red{
	color:var(--red);
}
.white{
	color:white;
}

img{
	user-select:none;
	user-drag: none;
	-webkit-user-drag: none;
}
strong{
	font-weight:bold;
}
a{

	text-decoration:none;
}
.H a:not(.block-link):hover{
	color:var(--red);
}
.H .block-link{
	transition:background-color .12s;
}
.H .block-link:hover{
	background:#F5F8FA;
}
.H .block-link.red-bg:hover{
	background:#cd0f18;
}
.H .block-link:hover .read-more{
	color:var(--red);
}

.logo{
	width:80px;
	height: 35px;
}

p+p{
	margin-top:1.45em;
}


.sidebar-links-cont{
	position: sticky;
	position: -webkit-sticky;
	top: calc(var(--menuHeight) + var(--gap));
	z-index:50;
padding-bottom:var(--gap);
pointer-events: none;
}
.sidebar-links{
	font-weight: 400;
	display:flex;
	flex-direction: column;
	user-select:none;
	pointer-events: all;

}
.sidebar-link{
	background:var(--red);
	color:white !important;
	text-align: center;
	display:block;

	padding: 0 8px;
	height: 40px;
	line-height:40px;
	transition:background-color .2s;
	 white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.sidebar-link+.sidebar-link{
	border-top: 1px solid var(--darkRed);
}
.sidebar-link.active{
	background:var(--darkRed);
}
.title{
	font-size: 24px;
	color: black;
	margin-bottom:.7em;
	line-height:initial;
}


.article a{
	color:var(--red);
}


.usn{
	user-select:none;
}


::-moz-selection{
	color: white;
	background: var(--red);
	text-shadow: none;
}
::selection{
	color: white;
	background: var(--red);
	text-shadow: none;
}


.stronger{
	font-weight:400;
	color:black;
}



.section-title{
	    text-align: center;
    font-size: 24px;
    color: var(--red);
    font-weight: 300;
}

.button{
	    background: black;
    color: white;
    height: 2.35em;
    padding: 0 .55em;
    display: flex;
    align-items: center;
    cursor: pointer;
}
