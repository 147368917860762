.news-row {
    grid-column-end: span 2;
    display: flex;
}

.news-row .block-content {
    flex: 1;
}

.news-row-small-info {
    display: flex;
}

.news-row-item {
    display: flex;
}

.news-row-small-title {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 850px) {
    .news-row {
        grid-column-end: span var(--blocks);
        display:block;
    }

    .news-latest img {
        min-width: 45%;
    }
}



@media screen and (max-width: 600px) {

    .block .news-row-small .thumb {
        width: 100px;
        height: 100px;
    }
    .news-row {
        display: block;
    }
     .news-row img {
        max-height: 250px;
        object-fit: contain;
        width: 100%;
        background: none;
    }
}


@media screen and (min-width: 601px) {
    .news-row .thumb {
        width: var(--blockWidth);
    }

    .news-latest {
        display: flex;
        flex-direction: row;
    }


}


.pager-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    color: var(--red);
}

.pager-icon.left {
    transform: rotate(180deg);
}

.pager-cont {
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--lightGrey);
}

.pager {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1.5em;
    height: 1.5em;
    font-size: 18px;
    font-weight: bold;
    margin: 20px;
}

.pager > * {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pager .active {
    color: var(--red);
}


.article-thumb {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: var(--blockWidth);
}

.prevnext {
    line-height: 1.8;
}

.prevnext.inactive {
    opacity: 0.4;
}

.prevnext.inactive .pager-icon {
    color: inherit;
}

.news-row-small-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.news-row-small {
    padding: 15px;
}

.news-row-small .thumb {
    width: 150px;
    height: 150px;
    margin-right: 20px;
}

.thumb.default {
    background: #f7f7f7;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    text-align: center;
    display: flex;
    padding: 10px;
    line-height: 1.2;
    text-transform: lowercase;
    color: var(--red);
}

.twitter-block {
    grid-column-end: span var(--blocks);
    grid-row-end: span 2;
}

@media screen and (min-width: 851px) {

    .twitter-block {
        grid-column-end: span 2;
    }


    .news-latest-1 {
        grid-row-end: span 2;
        flex-direction: column;
    }

    .news-latest-1 .thumb {
        width: 100% !important;
        flex-grow: 1;
        height: var(--blockWidth);

    }

    .news-latest-2 {
        order: 10;
    }

}


.filters {
    padding: 15px;
    display: grid;

    grid-gap: 15px;
    --h: 40px;
    border-bottom: 1px solid var(--lightGrey)
}

.filter-q {
    min-width: 0;
    background: #eee;
    flex: 1;
    height: var(--h);
    padding: 0 var(--h) 0 10px;
    font-weight: 400;
}

.filter-q-cont {

    width: var(--blockWidth);
    position: relative;
    display: flex;
}

.clear-input {
    position: absolute;
    height: 100%;
    width: var(--h);
    right: 0;
    top: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.clear-input svg {
    width: 24px;
}


.tab-links {
    display: flex;
}

.tab-link {

    padding: 0 .55em;
    display: flex;
    align-items: center;
    min-height: var(--h);
}

.H .tab-link:hover {
    background: black;
    color: white !important;
}

.tab-link.active {
    background: var(--red) !important;
    color: white;
}

.browser-back{
    margin-bottom:1em;
}

@media screen and (min-width: 700px) {
    .filters {
        grid-template-columns: var(--blockWidth) 1fr;
    }
}

@media screen and (max-width: 600px){
    .article-single .content-grid{
        display:flex;
        flex-direction:column;
    }
    .article-browser{
        order:-10;
        flex-direction: row;
        flex-wrap: wrap;
            justify-content: space-between;
    }
    .browser-back{
        width:100%;
    }
    .prevnext{
        display: flex;
    align-items: center;
    }
    .prevnext svg{
        margin:0 3px;
    }
    .prevnext:last-child svg{
        order:1;
    }

    .article-img.empty{
        display:none;
    }
}
